<template>
  <div class="customer-filter">
    <el-form size="small" ref="searchForm" :model="searchForm" :inline="true">
      <el-form-item label="关键字">
        <el-input
          v-model="searchForm.search"
          placeholder="关键词搜索"
          clearable
        ></el-input>
      </el-form-item>
      <el-form-item>
          <dateOptionSelector :options="dateOptions" v-model="searchForm.dateTime" :multiple="true" />
      </el-form-item>
      <el-form-item>
        <slot></slot>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import dateOptionSelector from "@/components/dateOptionSelector";
import enumSelector from "@/components/enumSelector";
export default {
  components: {
    enumSelector,
    dateOptionSelector,
  },
  props: {
    queryType: {
      default: "all",
    }
  },
  watch:{
   queryType: {
      handler(val) {
        
      },
      deep: true,
      immediate: true,
    },
  },
  data() {
    return {
      dateOptions: [
        { value: "create", name: '创建时间' },
      ],
      searchForm: {
        search: "",
        dateTime: {
          dateType: "create",
          date: [],
        },
      },
    };
  },
  methods: {
    getfilter() {
      let filters = {
        search: this.searchForm.search,
        queryType:this.queryType,
        dateType: this.searchForm.dateTime.dateType,
        beginDate:
          this.searchForm.dateTime.date.length > 0
            ? this.searchForm.dateTime.date[0]
            : "",
        endDate:
          this.searchForm.dateTime.date.length > 1
            ? this.searchForm.dateTime.date[1]
            : "",
      };
      return filters;
    },
  },
  mounted() {},
};
</script>
<style lang='scss' scoped>
/deep/.el-form-item {
  margin-bottom: 5px;
}
</style>